import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
// 头部标签组件
import VueMeta from 'vue-meta'


Vue.use(Element, {size: 'medium'})
Vue.use(VueMeta)


Vue.config.productionTip = false


new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
