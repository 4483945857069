<template>
  <div id="app">
    <transition mode="out-in" name="fade-transform">
      <keep-alive>
        <router-view/>
      </keep-alive>
    </transition>
  </div>
</template>

<script>

import Navbar from "@/components/Navbar";
export default {
  name: 'App',
  components: {Navbar},
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: title => {
        return title ? title + ' - ' + this.app : this.app
      }
    }
  },
  data() {
    return {
      nowYear: new Date().getFullYear(),
      size: {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    }
  },
  created() {
  },
  mounted() {
    window.addEventListener('resize', this.resize)
    this.$store.dispatch('app/changeSetting', {key: 'mobile', value: this.mobile})
  },
  computed: {
    title() {
      return this.$t(this.$route.meta.title)
    },
    app() {
      return this.$t('info.name')
    },
    mobile() {
      return this.size.width <= 992
    }
  },
  methods: {
    resize() {
      this.size.width = window.innerWidth
      this.size.height = window.innerHeight
      this.$store.dispatch('app/changeSetting', {key: 'size', value: this.size})
      this.$store.dispatch('app/changeSetting', {key: 'mobile', value: this.mobile})
    },
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: auto;
  position: relative;
  height: 100vh;
}

#app:has(.share) {
  overflow-x: hidden;
}

.el-menu.el-menu--horizontal {
  border: none !important;
}

.el-menu {
  .el-menu-item {
    border: none !important;
  }
}

</style>
