<template>
  <div>
    <Navbar/>
    <div class="main">
      <div class="content">
        <transition mode="out-in" name="fade-transform">
          <keep-alive>
            <router-view/>
          </keep-alive>
        </transition>
      </div>
    </div>
    <div class="footer">
      <span>©{{ nowYear }}</span>
      <el-link href="https://beian.miit.gov.cn" target="_blank"> 黔ICP备2021008900号-1</el-link>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "AppMain",
  components: {Navbar},
  data() {
    return {
      nowYear: new Date().getFullYear(),
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  box-sizing: border-box;
  padding: 20px;
  //height: calc(100vh - 61px);

  .content {
    min-height: calc(100vh - 160px);
  }
}

.header {
  position: sticky;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin: 0 !important;
  height: 60px;
  top: 0;
  z-index: 1;
  background-color: #fff;

  .right {
    text-align: right;
    line-height: 60px;
    padding-right: 30px !important;
  }

  .left {
    text-align: left;
    line-height: 60px;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  z-index: 1;
  width: 100%;
  padding: 20px 20px 0 20px;
  text-align: center;

  > * {
    padding: 0 10px;
  }
}
</style>
