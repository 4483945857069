import Vue from "vue"
import VueI18n from "vue-i18n/dist/vue-i18n"
import store from "@/store";

Vue.use(VueI18n)

const SUPPORT_LANGS = ['zh_CN', 'en_US']

// 获取config目录下所有js文件
let languageFields = require.context('./config', false, /\.js$/)

// 正则用于匹配'xx.js'中的'xx'
let regExp = /\.\/([^\.\/]+)\.([^\.]+)$/

let messages = {}

languageFields.keys().forEach(key => {
    // 匹配en_US|zh_CN之类的值
    let prop = regExp.exec(key)[1]
    messages[prop] = languageFields(key).default
})
let nl = navigator.language && navigator.language.replace(/-/g, '_')
// navigator.language获取浏览器语言
let locale = localStorage.getItem('lang') || nl || 'en_US'
if (!SUPPORT_LANGS.includes(locale)) {
    locale = 'en_US'
}

const setLang = function (lang) {
    if (!SUPPORT_LANGS.includes(lang)) {
        locale = 'en_US'
    }
    localStorage.setItem("lang", lang)
    i18n.locale = lang
    store.dispatch('app/changeSetting', {key: 'lang', value: lang})
}


const i18n = new VueI18n({
    // 指定语言字段
    locale,
    // 定义语言字段
    messages
})

// 存储在本地
setLang(locale)

export default i18n

export {
    setLang
}
